import {TRUE_STR} from 'core/constants.js';

export default (function () {
    var htmlReset = '';
    var bodyReset = '';

    var w = '820px'; // Specify the maximum width of iframe
    var GaClientId = '',
        GaCheckCount = 0;

    var widgetVersion = '';
    var iframeSrc = '';

    var getScrollPosition = function () {
        return (
            (document.documentElement && document.documentElement.scrollTop) ||
            document.body.scrollTop
        );
    };

    var createWidget = function (widgetEl, type, treatmentId) {
        var button = widgetEl;
        var wid_id = button.id;

        iframeSrc = document?.body?.dataset?.mh1BookingsUrl;

        if (iframeSrc?.includes('makatea')) widgetVersion = '/makatea';
        //get the source tracking parameter from HTML
        var sourceTrackingName = button.getAttribute('data-mh1-source');

        // Create popup
        var popup = document.createElement('div');

        // Insert the overlay into the DOM after the button element
        if (widgetEl.tagName == 'LI') {
            document.body.appendChild(popup);
            document.getElementsByTagName('body')[0].style.overflow = 'auto';
        } else {
            document.body.appendChild(popup);
        }

        // Click event to show the overlay and widget iframe
        button.onclick = function (e) {
            var wid_id = this.id;
            var wid_skin = this.getAttribute('skin');
            // Disable the default click handler for the popup button
            if (!e) e = window.event;
            if (e.preventDefault) e.preventDefault();
            e.returnValue = false;

            // store body inline styles so they can be reset
            htmlReset = document.documentElement.style.cssText;
            bodyReset = document.body.style.cssText;

            // Background element + styling
            var bg = document.createElement('div');
            bg.style.position = 'absolute';
            bg.style.zIndex = '0';
            bg.style.width = '100%';
            bg.style.height = '100%';
            bg.style.top = '0';
            bg.style.left = '0';

            // Background tranparent background
            try {
                bg.style.backgroundColor = 'rgba(0,0,0,0.85)';
            } catch (e) {
                // No rgba support - probably ie 7 or 8
                if (
                    navigator.appVersion.indexOf('MSIE 7.') != -1 ||
                    navigator.appVersion.indexOf('MSIE 8.') != -1
                ) {
                    // ie7/8
                    bg.style.background = 'url(#)'; // workaround to make transparent div clickable
                    bg.style.filter =
                        'progid:DXImageTransform.Microsoft.gradient(startColorstr=#CC000000, endColorstr=#CC000000)';

                    if (document.compatMode != 'CSS1Compat') {
                        // Quirks mode
                        bg.style.position = 'absolute';
                    }
                } else {
                    // fallback no tranparency
                    bg.style.backgroundColor = 'black';
                }
            }

            // close button element + styling
            var close = document.createElement('a');
            close.style.position = 'relative';
            close.style.top = '0';
            close.style.right = '.5em';
            close.style.float = 'right';
            close.style.fontSize = '48px';
            close.style.zIndex = '2';
            close.style.lineHeight = '1';
            close.style.margin = '0';
            close.style.textDecoration = 'none';
            close.style.color = '#888';
            close.style.cursor = 'pointer';
            close.innerHTML = ' &times; ';

            // iframe container element + styling
            var iframeContainer = document.createElement('div');
            iframeContainer.style.margin = '0 auto';

            iframeContainer.style['-webkit-overflow-scrolling'] = 'touch';

            iframeContainer.style.width = '100%';
            iframeContainer.style.height = '100%';
            iframeContainer.style.filter = 'none';
            iframeContainer.style.border = 'none';
            if (window.innerWidth <= 768) {
                iframeContainer.style.position = 'relative';
                iframeContainer.style.height = 'calc(100% - 48px)';
                iframeContainer.style.marginTop = '48px';
                close.style.right = '.2em';
            }
            // iframe element + styling
            var iframe = document.createElement('iframe');
            iframe.style.boxSizing = 'border-box';
            iframe.style.padding = '0';
            iframe.style.margin = '0';
            if (window.innerWidth > 768) {
                iframe.style.marginTop = '-48px';
            }
            iframe.style.border = 'none';
            iframe.style.position = 'relative';
            iframe.style.top = '0';
            iframe.style.left = '0';
            iframe.style.width = '100%';
            iframe.style.height = '100%';
            iframe.setAttribute('width', '100%');
            iframe.setAttribute('height', '100%');

            // Set the sandbox attribute
            if (document.body.dataset.mh1Sandbox == TRUE_STR) {
                iframe.setAttribute(
                    'sandbox',
                    'allow-scripts allow-forms allow-same-origin allow-popups allow-popups-to-escape-sandbox',
                );
            }

            if (sourceTrackingName) {
                iframe.setAttribute(
                    'src',
                    iframeSrc +
                        '/appointmentWidget' +
                        widgetVersion +
                        '?theme=popup&source=' +
                        sourceTrackingName +
                        '&' +
                        type +
                        '_id=' +
                        wid_id +
                        (treatmentId ? '&treatmentId=' + treatmentId : '') +
                        (wid_skin ? '&skin=' + wid_skin : '') +
                        (GaClientId ? '&clientId=' + GaClientId : '') +
                        (window.widgetTrackingGaId
                            ? '&widgetTrackingGaId=' +
                              window.widgetTrackingGaId
                            : ''),
                );
            } else {
                iframe.setAttribute(
                    'src',
                    iframeSrc +
                        '/appointmentWidget' +
                        widgetVersion +
                        '?theme=popup&' +
                        type +
                        '_id=' +
                        wid_id +
                        (treatmentId ? '&treatmentId=' + treatmentId : '') +
                        (wid_skin ? '&skin=' + wid_skin : '') +
                        (GaClientId ? '&clientId=' + GaClientId : '') +
                        (window.widgetTrackingGaId
                            ? '&widgetTrackingGaId=' +
                              window.widgetTrackingGaId
                            : ''),
                );
            }

            // add iframe to its container
            iframeContainer.appendChild(iframe);

            // popup styling
            popup.style.display = 'none';
            popup.style.position = 'fixed';
            popup.style.zIndex = '999999';
            popup.style.textAlign = 'center';
            popup.style.top = '0';
            popup.style.left = '0';
            popup.style.width = '100%';
            popup.style.height = '100%';
            if (
                navigator.appVersion.indexOf('MSIE 7.') != -1 ||
                navigator.appVersion.indexOf('MSIE 8.') != -1 ||
                navigator.appVersion.indexOf('MSIE 9.') != -1
            )
                popup.style.height = '100%';

            // add elements to popup
            popup.appendChild(bg);
            popup.appendChild(close);
            popup.appendChild(iframeContainer);

            // Click event to hide the overlay
            popup.getElementsByTagName('a')[0].onclick = function (e) {
                // hide popup
                popup.style.display = 'none';

                // clear popup children
                while (popup.lastChild) {
                    popup.removeChild(popup.lastChild);
                }

                // reset document styling
                document.documentElement.style.cssText = htmlReset;
                document.body.style.cssText = bodyReset;

                // ie7-8 quirks mode popup positioning workarounds
                if (
                    (navigator.appVersion.indexOf('MSIE 7.') != -1 ||
                        navigator.appVersion.indexOf('MSIE 8.') != -1) &&
                    document.compatMode != 'CSS1Compat'
                ) {
                    // ie7/8 quirks mode
                    document.getElementsByTagName('body')[0].style.overflow =
                        'auto';
                    popup.getElementsByTagName('div')[0].style.width = '80%';
                }
            };

            // prevent click events propagating though the iframe
            popup.getElementsByTagName('iframe')[0].onclick = function (e) {
                if (!e) e = window.event;
                e.cancelBubble = true;
                if (e.stopPropagation) e.stopPropagation();
            };

            // show and position popup
            popup.style.display = 'block';

            // prevent scrolling on document
            if (window.innerWidth > 1024) {
                document.documentElement.style.cssText =
                    'overflow: visible !important; height: 100% !important; max-height: 100% !important;';
            } else {
                document.documentElement.style.cssText =
                    'overflow: hidden !important; height: 100% !important; max-height: 100% !important;';
            }
            document.body.style.cssText =
                'overflow: hidden !important; height: 100% !important; max-height: 100% !important;';

            // ie7-8 quirks mode popup positioning
            if (
                (navigator.appVersion.indexOf('MSIE 7.') != -1 ||
                    navigator.appVersion.indexOf('MSIE 8.') != -1) &&
                document.compatMode != 'CSS1Compat'
            ) {
                // ie7/8 quirks mode
                document.getElementsByTagName('body')[0].style.overflow =
                    'hidden';
            }

            return false;
        };
    };

    var widget_setup = function () {
        var firstAvailableMenulist = document.getElementById('menu1st');

        // prevent # link scrolling to the top
        if (firstAvailableMenulist) {
            firstAvailableMenulist.onclick = function (e) {
                if (!e) e = window.event;
                if (e.preventDefault) e.preventDefault();
            };
        }

        var practiceList = document.getElementsByClassName(
            '1stAvailableWidget',
        );

        for (var i = 0; i < practiceList.length; i++) {
            var treatmentDiv = practiceList[i].getElementsByClassName(
                'treatmentId',
            );
            if (treatmentDiv != undefined && treatmentDiv.length > 0) {
                for (var j = 0; j < treatmentDiv.length; j++) {
                    var treatmentId = treatmentDiv[j].id;
                    createWidget(practiceList[i], 'practice', treatmentId);
                }
            } else {
                createWidget(practiceList[i], 'practice');
            }
        }

        var practitionerList = document.getElementsByClassName(
            '1stAvailableWidget2',
        );
        for (var i = 0; i < practitionerList.length; i++) {
            var treatmentDiv = practitionerList[i].getElementsByClassName(
                'treatmentId',
            );
            if (treatmentDiv != undefined && treatmentDiv.length > 0) {
                for (var j = 0; j < treatmentDiv.length; j++) {
                    var treatmentId = treatmentDiv[j].id;
                    createWidget(
                        practitionerList[i],
                        'practitioner',
                        treatmentId,
                    );
                }
            } else {
                createWidget(practitionerList[i], 'practitioner');
            }
        }
    };

    function checkIfWidgetTrackingPresent() {
        var gaFunction = window.ga || window[window.GoogleAnalyticsObject];
        if (gaFunction && window.widgetTrackingGaId) {
            if (typeof gaFunction !== 'undefined') {
                gaFunction('create', window.widgetTrackingGaId, 'auto');
                gaFunction(function (tracker) {
                    GaClientId = tracker.get('clientId');
                });
            }
        } else if (GaCheckCount < 10) {
            GaCheckCount++;
            setTimeout(checkIfWidgetTrackingPresent, 500);
        }
    }

    function initMyHealth1st() {
        widget_setup();

        // Following changes are for merging customer website session and mH1 widget session opened in iframe
        window['ga'] =
            window['ga'] ||
            function () {
                (window['ga'].q = window['ga'].q || []).push(arguments);
            };
        window['ga'].l = 1 * new Date();
        checkIfWidgetTrackingPresent();
    }

    return {'widgetSetup': initMyHealth1st};
})();
