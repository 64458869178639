import events from 'events.js';

export default async function setupSentry(Sentry, customTags = {}) {
    await events.ready();

    // Considering events.ready() has finished, document.body should be present at this point
    // No sense in configuring scope without document.body
    // since all the scope values are and will be added to document.body
    if (!document.body) {
        return;
    }

    Sentry.configureScope((scope) => {
        Object.entries(customTags).forEach(([tagName, tagValue]) => {
            Sentry.setTag(tagName, tagValue);
        });
        scope.addEventProcessor((event) =>
            Promise.resolve({
                ...event,
                environment: document.body.getAttribute('data-environment'),
            }),
        );
    });
}
