import throttle from 'common/throttle.js';

// Decorator takes rate limit time in ms and
// options as arguments. Passing {trailing: false}
// as options will disable trailing calls
export default function throttleDecorator(wait, options) {
    return function(target, key, descriptor) {
        const fn = descriptor.value;
        if (typeof fn !== 'function') {
            throw new SyntaxError(
                '@throttle decorator can only be applied to methods',
            );
        }

        // setter is required for mocking in tests,
        // same as writable: true on get and set
        return {
            get() {
                const throttled = throttle(fn.bind(this), wait, options);
                Object.defineProperty(this, key, {
                    configurable: false,
                    value: throttled,
                    writable: true,
                });
                return throttled;
            },
            set(value) {
                Object.defineProperty(this, key, {
                    configurable: false,
                    value,
                    writable: true,
                });
            },
        };
    };
}
