export default function throttle(func, delay, options = {}) {
    let timeout, args, wrapped;
    let lastExecuted = 0;

    // remove references from memory
    function cleanUpArgs() {
        if (!timeout) {
            args = null;
        }
    }

    function queued() {
        lastExecuted = Date.now();
        timeout = null;
        wrapped = func(...args);
        cleanUpArgs();
    }

    return function (...rest) {
        const now = Date.now();
        const remaining = delay - (now - lastExecuted);
        args = rest;
        if (remaining <= 0) {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }
            lastExecuted = now;
            wrapped = func(...args);
            cleanUpArgs();
        } else if (!timeout && options.trailing !== false) {
            timeout = setTimeout(queued, remaining);
        }
        return wrapped;
    };
}

export function asyncThrottle(func, delay, throwFunc = null) {
    let timerId;

    return function (...args) {
        if (timerId) {
            clearTimeout(timerId);
        }
        return new Promise((resolve, reject) => {
            timerId = setTimeout(async function () {
                try {
                    resolve(await func(...args));
                } catch (ex) {
                    if (throwFunc) {
                        reject(throwFunc(ex));
                    } else {
                        reject(ex);
                    }
                }
            }, delay);
        });
    };
}
