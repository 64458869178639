export function isValidAustralianPhoneNumber(phoneNumber) {
    // should be between 8 and 12 digits in a phone number
    let inDigitRange = false;
    const numberToDigits = phoneNumber.replace(/\D/g, '');
    if (numberToDigits.length >= 8 && numberToDigits.length <= 12) {
        inDigitRange = true;
    }

    // regex for valid Australian phone number including optional country code, hyphens and no letters
    // eslint-disable-next-line
    const isValid = /^[+]*[(]{0,1}[0-9]{1,2}[)]{0,1}[-\s\./0-9]*$/.test(
        phoneNumber,
    );

    return isValid && inDigitRange;
}

/**
 * Takes a string and returns whether it is a valid australian mobile number
 * @param {string} mobileNumber - string to test
 * @returns {boolean} true or false
 * @example
 *  '0400123456' // valid
 *  '0400 123 456' // valid
 *  '+61400123456' // valid
 *  '+61-400-123-456' // valid
 *  '0300123456' // invalid
 *  '+61300123456' // invalid
 *  '61400123456' // invalid
 *  '+62400123456' // invalid
 */
export function isValidAustralianMobileNumber(mobileNumber) {
    const trimmedNumber = mobileNumber.replace(/-|\s/g, '');
    const mobileRegex = /(^\+61(4|04)[0-9]{8}$)|(^04[0-9]{2})([0-9]{6})$/;
    return mobileRegex.test(trimmedNumber);
}

export function isValidEmailAddress(emailAddress) {
    // a simple email validation regular expression
    return !/^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/g.test(emailAddress);
}

function formatMobileNumberStr(formattedMobileNumber) {
    return `${formattedMobileNumber.slice(0, 4)} ${formattedMobileNumber.slice(
        4,
        7,
    )} ${formattedMobileNumber.slice(7)}`;
}
// get valid mobile number and format into '0000 000 000'
export function formatAustralianMobileNumber(validAustralianMobileNumber) {
    // Remove all non-numeric characters
    const cleanedNumber = validAustralianMobileNumber.replace(/[^\d]/g, '');

    //61426000111 or 610426000111 or 0426000111
    if (cleanedNumber.startsWith('61')) {
        const numberWithoutCountryCode = cleanedNumber.slice(2);
        if (
            numberWithoutCountryCode.length === 10 &&
            numberWithoutCountryCode.startsWith('0')
        ) {
            //0426000111
            return formatMobileNumberStr(numberWithoutCountryCode);
        }
        //426000111
        else if (numberWithoutCountryCode.length === 9) {
            const tenDigitNumber = '0' + numberWithoutCountryCode;
            return formatMobileNumberStr(tenDigitNumber);
        } else return;
    } else if (cleanedNumber.length === 10) {
        // If it's already 10 digits, return it as is
        return formatMobileNumberStr(cleanedNumber);
    } else return;
}
