export function getFocusableEls(containerId) {
    const container = document.getElementById(containerId);
    const focusableElements = container.querySelectorAll(
        'a[href], input, select, textarea, button, svg',
    );
    return Array.prototype.slice.call(focusableElements);
}

export function trapTabKey(focusableEls, event) {
    const firstElement = focusableEls[0];
    const lastElement = focusableEls[focusableEls.length - 1];
    let nextElement = null;

    if (!focusableEls.includes(document.activeElement)) {
        nextElement = firstElement;
    } else if (event.shiftKey && document.activeElement === firstElement) {
        nextElement = lastElement;
    } else if (document.activeElement === lastElement) {
        nextElement = firstElement;
    }

    if (nextElement) {
        event.preventDefault();
        nextElement.focus();
    }
}
